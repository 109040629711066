<template>
  <v-container>
    <v-row justify="center" dense no-gutters>
        <v-col cols="12" md="4">
         <v-btn color="secondary" outlined @click="$router.go(-1)"> <v-icon left>arrow_back</v-icon> Go Back</v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="1">
         <v-btn icon color="primary" @click="refresh" :loading="loading"><v-btn color="accent" class="white--text" @click="refresh" :loading="loading" rounded>Refresh</v-btn></v-btn>
        </v-col>
    </v-row>
    <v-row no-gutters dense>
      <v-col cols="12">
        <v-card flat>
          <v-card-title class="pt-0 pb-0">
            <v-row no-gutters dense>
            <!-- <v-btn @click="triggerDownload" color="primary" :loading="exportLoading" v-if="can('CAN_EXPORT_CONTACT')">Export </v-btn> -->
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-col>
            </v-row>
          </v-card-title>
          <v-data-table :headers="headers" :items="records" :search="search" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer :loading="loading">
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt | formatdate }}
              </template>
          </v-data-table>
          <div class="text-center">
            <v-pagination  v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'AboutUs',
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'session.chat.name' },
      { text: 'Phone Number', value: 'session.chat.phoneNumber' },
      { text: 'Date Created', value: 'createdAt' }
    ],
    exportLoading: false,
    loading: false,
    page: 1,
    itemsPerPage: 10,
    pageCount: 0,
    payload: { perPage: 10, page: 1 },
    records: []
  }),
  created () {
    this.triggerGetRecords()
  },
  watch: {
    page () {
      this.payload.page = this.page
      this.triggerGetRecords()
    }
  },
  methods: {
    ...mapActions({ getAboutUsSessions: 'dash/getAboutUsSessions' }),
    triggerGetRecords () {
      this.loading = true
      this.records = []
      this.getAboutUsSessions(this.payload).then(response => {
        this.records = response.data.data
        this.pageCount = response.data.totalPages
        this.loading = false
      }).catch(error => {
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        this.loading = false
      })
    },
    refresh () {
      this.loading = true
      this.triggerGetRecords()
      setTimeout(() => { this.loading = false }, 2000)
    }
  }
}
</script>
