<template>
  <v-container fluid>
    <v-row justify="center" dense no-gutters>
        <v-col cols="12" md="4">
         <v-btn color="secondary" outlined @click="$router.go(-1)"> <v-icon left>arrow_back</v-icon> Go Back</v-btn>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
    <v-row align="center" justify="center">
       <v-col cols="12" sm="4">
        <v-card class="text-center ma-2">
          <h3 class="pb-3 pt-3"> {{ securedMotorVehicleCount }}  </h3>
          <p class="pa-3 primary--text"> Motor Vehicle Sessions </p>
          <v-btn text class="pb-4" to="/secured_sessions/motor"> View More </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="text-center ma-2">
          <h3 class="pb-3 pt-3"> {{ securedTitleDeedCount }}  </h3>
          <p class="pa-3 primary--text"> Title Deed Sessions </p>
          <v-btn text class="pb-4" to="/secured_sessions/title"> View More </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Home',
  data: () => ({
    loading: true
  }),
  computed: mapState({
    securedMotorVehicleCount: state => state.dash.securedMotorVehicleCount,
    securedTitleDeedCount: state => state.dash.securedTitleDeedCount
  }),
  created () {
    this.$store.dispatch('dash/getSecuredMotorVehicleCount')
    this.$store.dispatch('dash/getSecuredTitleDeedCount')
  }
}
</script>
