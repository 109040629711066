var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center","dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("arrow_back")]),_vm._v(" Go Back")],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"icon":"","color":"primary","loading":_vm.loading},on:{"click":_vm.refresh}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"accent","loading":_vm.loading,"rounded":""},on:{"click":_vm.refresh}},[_vm._v("Refresh")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pt-0 pb-0"},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"search":_vm.search,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.nationalIdLink",fn:function(ref){
var item = ref.item;
return [(item.nationalIdLink)?_c('a',{attrs:{"href":item.nationalIdLink,"target":"_blank"}},[_vm._v(" ID Copy")]):_vm._e()]}},{key:"item.photo",fn:function(ref){
var item = ref.item;
return [(item.photo)?_c('a',{attrs:{"href":item.photo,"target":"_blank"}},[_vm._v(" Photo")]):_vm._e()]}},{key:"item.pinCertificateLink",fn:function(ref){
var item = ref.item;
return [(item.pinCertificateLink)?_c('a',{attrs:{"href":item.pinCertificateLink,"target":"_blank"}},[_vm._v(" Pin Cert")]):_vm._e()]}},{key:"item.logBook",fn:function(ref){
var item = ref.item;
return [(item.logBook)?_c('a',{attrs:{"href":item.logBook,"target":"_blank"}},[_vm._v(" Logbook")]):_vm._e()]}},{key:"item.mpesaStatement",fn:function(ref){
var item = ref.item;
return [(item.mpesaStatement)?_c('a',{attrs:{"href":item.mpesaStatement,"target":"_blank"}},[_vm._v(" Mpesa Statement")]):_vm._e()]}},{key:"item.companyRegCertificate",fn:function(ref){
var item = ref.item;
return [(item.companyRegCertificate)?_c('a',{attrs:{"href":item.companyRegCertificate,"target":"_blank"}},[_vm._v(" Company Reg Cert")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatdate")(item.createdAt))+" ")]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }